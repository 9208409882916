import { Action } from '@ngrx/store';
import * as FuelsActions from "./fuels.actions";
import { Fuel } from '../../models/fuel';
import { DispatchFuel } from '../../models/dispatchfuel';
import { ServerResponseModel } from '../../models/serverresponsemodel';

export function fuelsReducer(state: Fuel[], action: FuelsActions.Actions) {
    switch (action.type) {
        case FuelsActions.ActionTypes.GetCurrentGasoline:
            let currentGasoline: Fuel = action.payload;
            //return [state,{currentGasoline: currentGasoline}];
        case FuelsActions.ActionTypes.LoadGasolineFuel:
            return action.payload;
        default:
            return state;
    }
}

export function currentGasolineReducer(state: Fuel, action: FuelsActions.Actions) {
    switch (action.type) {
        case FuelsActions.ActionTypes.GetCurrentGasoline:
            return state;
        case FuelsActions.ActionTypes.AddCurrentGasolineFuel:
            return action.payload;
        default:
            return state;
    }
}

export function currentDieselReducer(
  state: Fuel,
  action: FuelsActions.Actions
) {
  switch (action.type) {
    case FuelsActions.ActionTypes.AddCurrentDieselFuel:
      return action.payload;
    default:
      return state;
  }
}

export function fuelsByDriverReducer(
    state: DispatchFuel[],
    action: FuelsActions.Actions
) {
    switch (action.type) {
        case FuelsActions.ActionTypes.LoadAllByDriver:
            return action.payload;
        default:
            return state;
    }
}

export function previousFuelBalanceReducer(
    state: ServerResponseModel,
    action: FuelsActions.Actions
) {
    switch (action.type) {
        case FuelsActions.ActionTypes.GetPreviousFuelBalance:
            return action.payload;
        default:
            return state;
    }
}