import { Action } from '@ngrx/store';
import { Fuel } from '../../models/fuel';
import { DispatchFuel } from '../../models/dispatchfuel';
import { ServerResponseModel } from '../../models/serverresponsemodel';

export enum ActionTypes {
  GetCurrentGasoline = "[Fuel Gasoline] Get Current",
  AddCurrentGasolineFuel = "[Fuel Current Gasoline] Add",
  AddCurrentDieselFuel = "[Fuel Current Diesel] Add",
  LoadGasolineFuel = "[Fuel Gasoline] Load",
  LoadDieselFuel = "[Fuel Diesel] Load",
  LoadAllByDriver = "[Fuel] Load All by Driver",
  GetPreviousFuelBalance = "[Fuel Balance] Get Previous Fuel Balance"
}

export class GetCurrentGasolineFuelAction implements Action {
    readonly type = ActionTypes.GetCurrentGasoline;
    constructor(public payload: Fuel) { }
}

export class AddCurrentGasolineFuelAction implements Action {
    readonly type = ActionTypes.AddCurrentGasolineFuel;
    constructor(public payload: Fuel) { }
}

export class AddCurrentDieselFuelAction implements Action {
    readonly type = ActionTypes.AddCurrentDieselFuel;
    constructor(public payload: Fuel) { }
}

export class LoadGasolineFuelAction implements Action {
    readonly type = ActionTypes.LoadGasolineFuel;
    constructor(public payload: Fuel[]) { }
}

export class LoadDieselFuelAction implements Action {
    readonly type = ActionTypes.LoadDieselFuel;
    constructor(public payload: number) { }
}

export class LoadAllByDriverAction implements Action {
    readonly type = ActionTypes.LoadAllByDriver;
    constructor(public payload: DispatchFuel[]) { }
}

export class GetPreviousFuelBalanceAction implements Action {
    readonly type = ActionTypes.GetPreviousFuelBalance;
    constructor(public payload: ServerResponseModel) { }
}

export type Actions =
    | GetCurrentGasolineFuelAction
    | AddCurrentDieselFuelAction
    | AddCurrentGasolineFuelAction
    | LoadGasolineFuelAction
    | LoadDieselFuelAction
    | LoadAllByDriverAction
    | GetPreviousFuelBalanceAction;